let base22MessageStyle =
  "font-size: 0px; line-height: auto; padding: 60px 160px; background-size: 32px 132px; background: url(https://gitlab.2mundos.net/uploads/-/system/appearance/logo/1/2mundos-google-logo2.png); background-repeat: no-repeat;";

/* eslint-disable no-console */
console.log("%c2Mundos", base22MessageStyle);
/* eslint-enable no-console */

class Config {
  constructor() {
    this.env = process.env.APP_ENV;
    this.isMobile =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1;
  }
}

export default Config;
