let globals_br = {
  showcase: {
    slide_1: {
      title: "Integramos Hardware e Software"
    },
    slide_2: {
      title: "Cursos Interativos"
    },
    slide_3: {
      title: "Fábrica de Inovação e Tecnologia"
    }
  },
  buttons: {
    learn_more: "Saiba Mais",
    send_now: "Enviar agora"
  },
  scroll_to_begin: "Rolar para começar",
  places: {
    brazil: "Brasil",
    spain: "Espanha",
    california: "Califórnia"
  },
  home: {
    who_we_are: {
      title: "Quem Somos",
      text_1:
        "Com operação no Brasil, EUA e Europa a 2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Nosso time possui experiência na criação de ambientes digitais, da concepção de novos produtos até o aperfeiçoamento de plataformas em operação.",
      text_2:
        "Nós ajudamos dezenas de Startups e corporações nos últimos anos, auxiliando seus fundadores e times com o suporte tecnológico necessário para desenvolver suas ideias em todas as etapas de produção, inclusive após o lançamento da versão inicial do projeto."
    },
    expertise: {
      title: "Expertise",
      text_1:
        "Elaboramos projetos complexos de software e hardware em todos os estágios, desde o desenvolvimento da ideia, criação da experiência do usuário, design e prototipagem, passando pelas etapas de programação e garantia de qualidade até a entrega e suporte à solução.",
      text_2:
        'A 2Mundos também possui uma vasta experiência na gestão de produtos e projetos digitais em produção por meio de "live teams".',
      edtech: {
        title: "EdTech",
        text:
          "Soluções que potencializam a experiência do aluno, com uso de tecnologia através de plataformas, aplicativos e ferramentas que aplicam realidade virtual, inteligência artificial e gamificação ao ensino."
      },
      iot: {
        title: "IoT",
        text:
          "Precisa de uma solução em hardware? Nosso time de IoT está pronto para pensar e desenvolver qualquer projeto, seja ele um hardware, placa de circuito impresso (PCB), circuitos analógicos ou digitais e firmware para plataformas 8 ou 32 bits."
      },
      software: {
        title: "Software",
        text:
          "Seu projeto pensado para ser leve, ágil, usando as melhores tecnologias do mercado e com o que há de mais moderno em hardware e software. Nossas equipes estão sempre em busca de novidades para que você tenha sempre o melhor produto."
      },
      ar_vr: {
        title: "AR/VR",
        text:
          "Dê vida à sua ideia! Seja ela através da realidade virtual ou aumentada, a 2Mundos possui vasta experiência na criação de experiências virtuais para treinamentos, educação, entretenimento ou ações de marca."
      }
    },
    our_team: {
      title: "Equipes",
      text_1:
        "Somos motivados pelo desafio, pela tecnologia, pela inovação... Composta por um time de mais de 60 profissionais multidisciplinares, com experiência nos mais diversos tipos de projetos, nossa equipe está em constante evolução e em busca de conhecimento, processos e tecnologias a serem aplicadas aos desafios diários.",
      text_2:
        "Pensou em algo impossível de ser realizado?  Você está frustrado com os resultados que outras empresas têm apresentado para seu projeto? Esses são os desafios certos para o time da 2Mundos, sejam eles para o mundo real ou virtual."
    },
    clients: {
      title: "Clientes",
      text:
        "Com clientes na América Latina, Estados Unidos e Europa, as equipes da 2Mundos possuem experiência internacional. Isso nos ajuda a proporcionar as melhores soluções em tecnologia para mercados em constante evolução."
    },
    cases: {
      title: "Cases",
      text_1:
        "Ideias que se transformam em soluções, esse é o nosso negócio! Conheça alguns dos desafios que chegaram como simples ideias e, aliados à expertise da 2Mundos, se tornaram produtos que hoje atendem milhões de usuários.",
      text_2:
        "Não importa o tamanho do seu projeto ou do seu negócio. Temos a equipe certa para desenvolver sua ideia."
    }
  },
  about: {
    title: "Nós somos uma Fábrica de inovação e tecnologia",
    text_1:
      "Com operação no Brasil, EUA e Europa a 2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Nosso time possui experiência na criação de ambientes digitais, da concepção de novos produtos até o aperfeiçoamento de plataformas em operação.",
    text_2:
      "Nós ajudamos dezenas de Startups e corporações nos últimos anos, auxiliando seus fundadores e times com o suporte tecnológico necessário para desenvolver suas ideias em todas as etapas de produção, inclusive após o lançamento da versão inicial do projeto.",
    management_team: {
      title: "equipe de gestão",
      eduardo: {
        name: "Eduardo",
        text_1:
          "Eduardo possui sólida experiência em gestão administrativa e comercial, adquiridas durante mais de 10 anos de liderança em empresas de pequeno, médio e grande porte, nos setores de construção civil, médico e tecnologia.",
        text_2:
          "Eduardo atuou como CEO da CPC Rental, empresa na área de locação de equipamentos pesados, sendo responsável pela gestão administrativa, operacional, comercial e prospecção de novos negócios e como Executivo da LifeCode Brasil, sendo responsável pelo departamento comercial e prospecção de novos negócios.",
        text_3:
          "Atualmente como Diretor Administrativo, Comercial e Financeiro na 2Mundos inc., Eduardo é responsável pela gestão financeira e comercial, contratos, desenvolvimento de cenários para tomada de decisão, custeio de novos projetos e investimentos, além do comportamento organizacional e otimização de processos produtivos."
      },
      mauricio: {
        name: "Mauricio",
        text_1:
          "Mauricio Piacentini tem mais de 25 anos de experiência como lead designer e programador, tendo participado do desenvolvimento de mais de 200 aplicativos e jogos. Como programador fundador e líder da empresa Tabuleiro ele coordenou o design e o desenvolvimento de pacotes de middleware para as ferramentas Macromedia Shockwave nos anos 90, utilizados por dezenas de milhares de desenvolvedores em mais de 90 países diferentes.",
        text_2:
          "Depois disso, Mauricio ajudou a fundar a 2Mundos Inc e lidera a equipe de engenharia e P & D. Um programador habilidoso com um forte histórico de C / C ++, ele desenvolveu para a maioria dos sistemas operacionais de desktop lançados desde 1994, incluindo todos os tipos de Windows, MacOS e Linux. Possui profundo conhecimento de uma variedade de linguagens de script e programação, frameworks e APIs, incluindo Carbon/Cocoa, Win32, DirectX, OpenGL, Qt, Linux, JavaScript, PHP, Java e outros."
      },
      raphael: {
        name: "Raphael",
        text_1:
          "Raphael Hamzagic já acumula duas décadas de experiência como programador tendo passado por empresas produtoras de projetos multimídia, estúdio de games e uma das principais agências de publicidade do mercado. O alto grau de interatividade e inovação tecnológica estão presentes nos principais projetos que marcaram sua trajetória.",
        text_2:
          "Raphael está na 2Mundos desde sua fundação, sempre buscando produzir com eficiência, qualidade e rigorosamente no prazo. Essas três características cativaram os clientes norte-americanos, com quem tem trabalhado de perto nos últimos 5 anos."
      },
      bernardo: {
        name: "Bernardo",
        text_1:
          "Bernardo Manfredini é formado em Publicidade e Propaganda pela ECA-USP, pós-graduado em Administração de Empresas pelo IBMEC e tem um Máster em Project Management pela EAE Business School de Madri. Começou a atuar em tecnologia há mais de 15 anos na Ubisoft, passando por empresas como UOL e iG antes de começar a empreender. Em 2008 formou parte da equipe fundadora do clube de compras Coquelux, iniciativa que passou por rodadas de investimentos de fundos como Intel Capital. ",
        text_2:
          "Desde 2011 Bernardo faz parte da 2Mundos, onde chegou logo após sua fundação. Tem atuado em diversas áreas da empresa sempre com o foco na busca de novos negócios, que os projetos superem as expectativas dos clientes e que a empresa possa ter um ambiente de trabalho especial para reter talentos, sem nunca perder de vista a eficiência da organização."
      },
      antonio: {
        name: "Antonio",
        text_1:
          "Antonio Marcio Curi trabalha na indústria de tecnologia há mais de 15 anos. Iniciou sua experiência profissional em San Diego, na Hands-on Mobile, como desenvolvedor líder de aplicativos e jogos para dispositivos móveis, onde ocupou diversos cargos nas equipes de engenharia e negócios. Depois disso, Antonio foi contratado e transferido para o Brasil pela Electronic Arts, a maior empresa de videogames do mundo, para auxiliar na abertura de operações da empresa na América Latina.",
        text_2:
          "Após 4 anos na EA, Antonio mudou-se para a Qualcomm Inc, onde deixou o cargo de Gerente Sênior de Desenvolvimento de Negócios na América Latina, para ser um dos cofundadores da 2Mundos Inc em 2011. Desde então, a 2Mundos cresceu contando hoje com mais de 70 funcionários atendendo clientes em todo o mundo. Antonio é formado em Engenharia de Computação e assessor em diversas empresas de tecnologia ao redor do mundo."
      }
    },
    our_teams: {
      title: "Nossos Times",
      ux_ui: {
        title: "UX/UI",
        text_1:
          "A experiência do usuário é o que importa! Experiência do Usuário (UX) e Interface do Usuário (UI) são peças importantes para o sucesso da estratégia no mundo digital. Não há porque escolher apenas um deles, ou achar que competem entre si. UX é responsável pela sensação do usuário ao acessar uma aplicação ou navegar por um conteúdo, enquanto UI é responsável por guiar o usuário durante o uso da solução.",
        text_2: "UI é sobre razão, UX é sobre emoção.",
        text_3:
          "Com a evolução da tecnologia e a quantidade de serviços e produtos oferecidos atualmente, as empresas que se destacam são justamente as que pensam na experiência do cliente e nisso a 2Mundos é especialista. Todas as interfaces de produtos são elaboradas por nossa equipe de UX/UI, juntamente com designers e ilustradores, tornando a experiência e navegação mais rápida, prática e intuitiva."
      },
      iot: {
        title: "IOT",
        text:
          "Precisa de uma solução em hardware? Nosso time de IoT está pronto para pensar e desenvolver qualquer projeto, seja ele um hardware, o desenvolvimento de uma placa de circuito impresso (PCB), circuitos analógicos ou digitais e firmware para plataformas 8 ou 32 bits. "
      },
      ai: {
        title: "INTELIGÊNCIA ARTIFICIAL",
        text:
          "A inteligência Artificial está mudando a maneira de se fazer negócios em diversos segmentos do mercado. Mais e mais empresas estão utilizando a inteligência artificial para economizar recursos e aumentar sua produtividade, sejam elas gigantes do mercado ou startups. A inteligência artificial aplicada ao mundo dos negócios permite análises de enormes volumes de dados em pouco tempo. Processos que demoravam dias ou semanas, agora podem ser realizados em poucos minutos.  Nossas plataformas de inteligência artificial são facilmente escaláveis e se integram perfeitamente com a infra-estrutura tecnológica existente. Precisando de mais inteligência para o seu negócio? Vem para a 2Mundos!"
      },
      engenharia: {
        title: "ENGENHARIA DE SOFTWARE",
        text:
          "Seu projeto pensado para ser leve, ágil, usando as melhores tecnologias do mercado e com o que há de mais moderno em hardware e software. Nossas equipes estão sempre em busca de novidades para que você tenha o melhor produto. A fundação é a base de tudo, por isso na 2Mundos primeiro entendemos as necessidades do cliente e requisitos do produto a ser desenvolvido. Após a análise dessas informações, nossos engenheiros de software avaliam toda a estrutura, pensando nas melhores tecnologias, linguagens e plataformas para que a solução atenda integralmente às expectativas do usuário final."
      },
      infra: {
        title: "INFRAESTRUTURA",
        text:
          "Uma infraestrutura tecnológica bem definida é a base para qualquer projeto. A decisão sobre uma infraestrutura tem importância estratégica, já que limitará ou potencializará a escalabilidade da solução. Por esse motivo, pensamos em todos os seus componentes de software e hardware. Uma infraestrutura sólida permitirá que a solução opere de maneira eficiente e eficaz, com elevados níveis de serviço e benefícios."
      },
      ar_vr: {
        title: "AR/VR",
        text:
          "Presentes cada vez mais em nosso dia a dia, as tecnologias de realidade virtual e realidade aumentada estão sendo utilizadas em jogos, aplicativos, além de áreas como a medicina e a educação. Elas permitem trazer elementos do mundo virtual para o mundo real, mudando assim nossa percepção de situações complexas, oferecendo suporte a diversas tarefas do cotidiano. Com a expertise da 2Mundos, é possível desenvolver ambientes totalmente digitais, que permitem ao usuário ter uma imersão no assunto, como é o caso da realidade virtual, ou simplesmente inserindo informações virtuais no mundo real ,no caso da Realidade Aumentada. Dê vida a sua idéia! Seja ela através da realidade virtual ou aumentada, a 2Mundos possui vasta experiência na criação de experiências virtuais para treinamentos, educação, entretenimento ou ações de marca, utilizando as mais novas ferramentas de mercado."
      },
      gestao: {
        title: "GESTÃO DE PROJETOS",
        text:
          "Em meio ao cenário atual de constantes mudanças tecnológicas, a gestão de projetos vem se tornando uma peça fundamental na melhoria da performance de uma empresa. Com o passar dos anos, as estruturas empresariais tornaram-se mais complexas, exigindo que todos se adaptem às novas realidades do mercado. A equipe de gestão de projetos da 2Mundos acompanha cada projeto desde o planejamento, desenvolvendo cronogramas de entregas e gerenciamento de riscos, passando por todo monitoramento durante a execução e conclusão de cada etapa até a entrega do produto final, utilizando as mais recentes metodologias para acompanhamento e controle. Um projeto bem administrado se torna mais ágil e assertivo, reduzindo custos, tempo de desenvolvimento e riscos."
      },
      qa: {
        title: "QUALITY ASSURANCE",
        text:
          "Atestamos a qualidade de nossos produtos em cada fase do projeto, através de uma equipe altamente treinada, reduzindo a probabilidade de falhas. Nossa equipe de QA é responsável por testar e atestar a qualidade de nossas soluções realizando testes exaustivos a cada nova versão de build. Dessa forma, garantimos maior estabilidade nas entregas e redução de riscos de falhas no produto final, pois os problemas são sanados com mais rapidez."
      }
    }
  },
  jobs: {
    title: "Trabalhe Conosco",
    apply: "Candidate-se",
    oportunities: {
      title: "Oportunidades",
      text_1:
        "Venha fazer parte de uma empresa que tem como propósito desenvolver e incentivar o melhor das pessoas!",
      text_2:
        "Tendo a inovação em nosso DNA, nos empenhamos em criar os melhores produtos para os nossos clientes. Temos orgulho de trabalhar com empresas na América Latina, EUA e Europa. Nossos clientes são exigentes, por isso formamos uma equipe de alto nível, composta por profissionais realmente interessados em trabalhar seguindo os exigentes padrões internacionais de prazo e qualidade. Na 2Mundos você vai encontrar um ambiente que desperta a criatividade, incentiva a autonomia, te encoraja a buscar novas habilidades e se desafiar todos os dias.",
      text_3:
        "Somos uma empresa que acredita nas pessoas e promove a diversidade. Por isso, incentivamos um ambiente profissional que valorize as diferenças e seja guiado sempre pela meritocracia, não pelas aparências, crenças ou visões de mundo. Profissionais que evoluem rapidamente dentro de nosso ambiente, rapidamente são convidados a assumir maiores responsabilidades e veem a carreira acelerar em pouco tempo. Temos diversos exemplos assim em nossos times. Buscamos profissionais engajados e com grande senso de realização. Se você tem paixão pelo que faz, vontade de aprender, e espírito colaborativo, junte-se a nós!",
      text_4: "Confira abaixo algumas vagas abertas."
    }
  },
  cases: {
    title: "Nossos Cases",
    our_cases: {
      title: "Cases"
    }
  },
  contact: {
    title: "Contato",
    text:
      "A 2Mundos conta com escritórios nos Estados Unidos, Brasil e Espanha. Precisa colocar uma idéia em prática? Quer transformá-la em um produto ou serviço? Estamos aqui para ajudar com a tecnologia necessária.",
    form: {
      placeholder: {
        name: "Nome",
        email: "Email",
        phone: "Telefone",
        message: "Mensagem",
        dont_fill: "Se você está vendo isso, não preencha esta entrada.",
        error_message:
          "opss... algum problema ocorreu, por favor tente novamente!"
      }
    }
  },
  header: {
    home: "Home",
    about: "Quem somos",
    cases: "Cases",
    jobs: "Trabalhe conosco"
  },
  footer: {
    links: {
      home: "Home",
      about: "Quem somos",
      cases: "Cases",
      jobs: "Trabalhe conosco"
    },
    all_rights_reserved: "Todos os Direitos Reservados"
  }
};

export default globals_br;
