let globals_us = {
  showcase: {
    slide_1: {
      title: "We integrate Hardware and Software"
    },
    slide_2: {
      title: "Interactive Courses"
    },
    slide_3: {
      title: "Innovation and Technology Factory"
    }
  },
  buttons: {
    learn_more: "Learn more",
    send_now: "Send now"
  },
  scroll_to_begin: "Scroll to begin",
  places: {
    brazil: "Brazil",
    spain: "Spain",
    california: "California"
  },
  home: {
    who_we_are: {
      title: "Who we are",
      text_1:
        "2Mundos Inc. is a factory of innovation and technology, specializing in customized solutions for software and IoT, meeting the demands of its customers in the most diverse markets around the world. Our team has extensive experience in creating digital environments, developing custom mobile and web apps that scale.",
      text_2:
        "We have helped dozens of startups and corporations in recent years, delivering end-to-end software and technology solutions that enhance productivity and customer satisfaction. "
    },
    expertise: {
      title: "Expertise",
      text_1:
        "From strategy to UI/UX design to digital prototyping, we are a full-service development company with expertise in designing and developing innovative mobile and web products for all devices. With a strong focus on emerging technologies (AI, machine learning, voice, data, blockchain, IOT), our team is here to lead the growth of your next successful venture.",
      text_2:
        "We think big, design smart and develop fast for all screens, projects and teams. Serving global leaders to entrepreneurs, we tailor our process based on your scale and structure. Our strategists, designers, architects, developers and quality analysts excel in every technology powering native, hybrid, cross-platform and custom software development.",
      edtech: {
        title: "EdTech",
        text:
          "Solutions that potentialize the student's experience, using technology through platforms, applications and tools that apply virtual reality, artificial intelligence and gamification to teaching."
      },
      iot: {
        title: "IoT",
        text:
          "Need a hardware solution? Our IoT team is ready to think and develop any project, be it a hardware, printed circuit board (PCB), analog or digital circuits, and firmware for any platform."
      },
      software: {
        title: "Software",
        text:
          "Your project is designed to be lightweight, agile, using the best technologies in the market and with the latest in hardware and software. Our team is always looking for cutting edge technology, so you always have the best product."
      },
      ar_vr: {
        title: "AR/VR",
        text:
          "Bring your idea to life! Be it through virtual or augmented reality, 2Mundos has vast experience in creating virtual experiences for training, education, entertainment and brand actions."
      }
    },
    our_team: {
      title: "Our Team",
      text_1:
        "We are motivated by the challenge, technology, innovation... Consisting of a team of more than 60 multidisciplinary professionals, with experience in several types of projects, our team is constantly evolving and in search of knowledge, processes and technologies to be applied to daily challenges.",
      text_2:
        "We scale quickly and manage all stages of the software lifecycle, from UX to delivery, amplifying your team’s custom software development capabilities for as long or little as you need."
    },
    clients: {
      title: "Clients",
      text:
        "With clients in United States, Europe, and LATAM, 2Mundos’ team have a lot of experience in many different markets. This helps us provide the best technology solutions for constantly evolving markets around the world."
    },
    cases: {
      title: "Cases",
      text_1: "Ideas that turn into solutions, this is our business!",
      text_2:
        "Meet some of the challenges that came as simple ideas and partnered with the expertise of 2Mundos, have become products that serves millions of users today. No matter the size of your project or your business. We have the right team to help you. "
    }
  },
  contact: {
    title: "Contact",
    text:
      "2Mundos has offices in San Diego, Madrid, and Sao Paulo. Do you have to put an idea into practice? Do you want your idea to turn it into a product or service? We're here to help with the necessary technology. Your idea doesn't come out of the paper? Don't give up, click here!",
    form: {
      placeholder: {
        name: "Name",
        email: "Email",
        phone: "Phone",
        message: "Message",
        dont_fill: "If you are seeing this, do not fill out this input.",
        error_message: "oops ... some problem occured, please try again!"
      }
    }
  },
  footer: {
    links: {
      home: "Home",
      who_we_are: "Who we are",
      cases: "Cases",
      work_with_us: "Work with us"
    },
    all_rights_reserved: "All Rights Reserved"
  }
};

export default globals_us;
