<template>
  <div>
    <Particles />
    <div class="bg-texture"></div>
    <div id="loading" :class="{'visible': loading}"></div>
    <transition
      mode="out-in"
      name="fade"
      enter-active-class="fade-enter"
      leave-active-class="fade-leave"
      duration="500"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Particles from "../../components/Particles/Particles";

export default {
  name: "Container",
  components: {
    Particles
  },
  data() {
    return {
      loading: true
    };
  },
  created() {
    let vm = this;
    vm.$bus.$on("load", function(data) {
      vm.loading = data;
    });
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>