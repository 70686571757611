import { us } from "./translations/us";
import { br } from "./translations/br";
import { es } from "./translations/es";

const translations = {
  us,
  br,
  es
};

export var messages = translations;
