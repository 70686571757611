import Vuex from "vuex";

const store = new Vuex.Store({
  modules: {},
  state: {
    languages: "",
    currentLanguage: "br"
  },
  actions: {
    UPDATE_CURRENT_LANGUAGE({ commit }, _payload) {
      commit("update_language", _payload);
    }
  },
  mutations: {
    update_language(state, _payload) {
      state.currentLanguage = _payload;
    }
  },
  getters: {
    languages: state => {
      return state.languages;
    },
    current_language(state) {
      return state.currentLanguage;
    }
  }
});

export default store;
