let routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/pages/Home/Home"),
    meta: {
      title: "More than a software house",
      metaTags: [
        {
          name: "description",
          content:
            "2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Confira!"
        },
        {
          name: "keywords",
          content:
            "Software House, IoT, Edtech, Desenvolvimento android, Realidade Virtual, desenvolvimento iOS"
        },
        {
          property: "og:title",
          content: "Desenvolvimento de Software e Hardware é com a 2Mundos!"
        },
        {
          property: "og:site_name",
          content: "2Mundos"
        },
        {
          property: "og:description",
          content:
            "2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Confira!"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: "http://www.2mundos.net/"
        }
      ]
    }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/About/About"),
    meta: {
      title: "About us",
      metaTags: [
        {
          name: "description",
          content:
            "2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Confira!"
        }
      ]
    }
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import(/* webpackChunkName: "jobs" */ "@/pages/Jobs/Jobs"),
    meta: {
      title: "Work with us",
      metaTags: [
        {
          name: "description",
          content:
            "2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Confira!"
        }
      ]
    }
  },
  {
    path: "/cases",
    name: "cases",
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/pages/Cases/Cases"),
    meta: {
      title: "Our cases",
      metaTags: [
        {
          name: "description",
          content:
            "2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Confira!"
        }
      ]
    }
  }
];

export default routes;
