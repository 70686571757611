import Vue from "vue";
import "vue-use-vuex";
import App from "./App.vue";
import store from "./store";
import router from "./config/router";
import "../src/assets/styles/main.scss";
import Config from "./config/config";
import VueI18n from "vue-i18n";
import { messages } from "./language/language";
import { TweenMax } from "gsap";
import VueScrollmagic from "vue-scrollmagic";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";

Vue.config.productionTip = false;

Vue.prototype.CONFIG = new Config();
Vue.use(VueI18n);
Vue.use(VueScrollmagic);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueTheMask);

require("./config/eventBus");

const languageCode = 'br'; // 'es', 'us'
store.state.currentLanguage = languageCode;

const i18n = new VueI18n({
  locale: store.state.currentLanguage,
  fallbackLocale: process.env.NODE_ENV === "development" ? "xx" : languageCode ? languageCode : "us",
  messages
});
store.state.languages = Object.keys(messages);

new Vue({
  store,
  router,
  i18n,
  TweenMax,
  render: h => h(App)
}).$mount("#app");
