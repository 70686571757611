let globals_es = {
  showcase: {
    slide_1: {
      title: "INTEGRAMOS  HARDWARE Y SOFTWARE"
    },
    slide_2: {
      title: "CURSOS INTERACTIVOS"
    },
    slide_3: {
      title: "Fábrica de Innovación y Tecnología"
    }
  },
  buttons: {
    learn_more: "aprende más",
    send_now: "Enviar ahora"
  },
  scroll_to_begin: "Rodar para comenzar",
  places: {
    brazil: "Brazil",
    spain: "España",
    california: "California"
  },
  home: {
    who_we_are: {
      title: "QUIENES SOMOS",
      text_1:
        "Contamos con operación en Brasil, EE. UU., y Europa, 2Mundos Inc. es una fábrica de innovación y tecnología, especializada en soluciones de software a medida e IoT. Atendemos los requerimientos de nuestros clientes en los mercados más diversos.",
      text_2:
        "Hemos ayudado a múltiples Startups y corporaciones en los últimos años a desarrollar sus productos digitales en todas las etapas del ciclo de vida, desde el diseño hasta su lanzamiento y puesta en producción."
    },
    expertise: {
      title: "EXPERIENCIA",
      text_1:
        "Desarrollamos proyectos complejos de software y hardware en todas sus etapas, desde la maduración de ideas, creación de experiencia de usuario, diseño y creación de prototipos, pasando por la programación y el control de calidad hasta la entrega y el soporte de soluciones.",
      text_2:
        "2Mundos también tiene una amplia experiencia en gestión de productos y proyectos digitales en producción a través de “live teams”.",
      edtech: {
        title: "EdTech",
        text:
          "Soluciones que mejoran la experiencia del estudiante, utilizando tecnología a través de plataformas, aplicaciones y herramientas que aplican realidad virtual, inteligencia artificial y gamificación a la enseñanza."
      },
      iot: {
        title: "IoT",
        text:
          "¿Necesita una solución de hardware? Nuestro equipo de IoT está listo para pensar y desarrollar cualquier proyecto, ya sea un hardware, una placa de circuito impreso (PCB), circuitos analógicos o digitales y firmware para plataformas de 8 o 32 bits."
      },
      software: {
        title: "Software",
        text:
          "Desarrollamos de forma sencilla y  ágil, utilizando las mejores tecnologías del mercado y con lo más moderno en hardware y software. Los equipos técnicos siempre están buscando novedades para que nuestros clientes tengan el mejor producto."
      },
      ar_vr: {
        title: "AR/VR",
        text:
          "¡Dale vida a tu idea! Ya sea a través de la realidad virtual o aumentada, 2Mundos tiene una vasta experiencia en la creación de experiencias virtuales para capacitación, educación, entretenimiento o acciones de marca."
      }
    },
    our_team: {
      title: "EQUIPOS",
      text_1:
        "Nos motiva el desafío, la tecnología, la innovación ... Somos un equipo de más de 60 profesionales multidisciplinarios, con experiencia en los más diversos tipos de proyectos. Nuestro equipo está en constante evolución, en búsqueda de nuevos conocimientos y tecnologías para ser aplicados a los proyectos.",
      text_2:
        "Pensó en algo imposible de lograr! ¿Está frustrado con los resultados que otras empresas han presentado para su proyecto? Estos son los desafíos correctos para el equipo de 2Mundos, ya sea real o virtual."
    },
    clients: {
      title: "Clientes",
      text:
        "Contamos con vasta experiencia internacional, hemos desarrollados con éxito proyectos para clientes en América Latina, Estados Unidos y Europa."
    },
    cases: {
      title: "Cases",
      text_1:
        "Conozca algunos de los desafíos que surgieron como ideas simples y, junto con la experiencia de 2Mundos, se han convertido en productos que hoy en día sirven a millones de usuarios.",
      text_2:
        "No importa el tamaño de su proyecto o su negocio. Contamos con el equipo adecuado para desarrollar tu idea."
    }
  },
  about: {
    title: "Nós somos uma Fábrica de inovação e tecnologia",
    text_1:
      "Com operação no Brasil, EUA e Europa a 2Mundos Inc. é uma fábrica de inovação e tecnologia, especializada em soluções customizadas para software e IoT, atendendo as demandas de seus clientes nos mais diversos mercados ao redor do mundo. Nosso time possui experiência na criação de ambientes digitais, da concepção de novos produtos até o aperfeiçoamento de plataformas em operação.",
    text_2:
      "Nós ajudamos dezenas de Startups e corporações nos últimos anos, auxiliando seus fundadores e times com o suporte tecnológico necessário para desenvolver suas ideias em todas as etapas de produção, inclusive após o lançamento da versão inicial do projeto.",
    management_team: {
      title: "equipe de gestão",
      eduardo: {
        name: "Eduardo",
        text_1:
          "Eduardo possui sólida experiência em gestão administrativa e comercial, adquiridas durante mais de 10 anos de liderança em empresas de pequeno, médio e grande porte, nos setores de construção civil, médico e tecnologia.",
        text_2:
          "Eduardo atuou como CEO da CPC Rental, empresa na área de locação de equipamentos pesados, sendo responsável pela gestão administrativa, operacional, comercial e prospecção de novos negócios e como Executivo da LifeCode Brasil, sendo responsável pelo departamento comercial e prospecção de novos negócios.",
        text_3:
          "Atualmente como Diretor Administrativo, Comercial e Financeiro na 2Mundos inc., Eduardo é responsável pela gestão financeira e comercial, contratos, desenvolvimento de cenários para tomada de decisão, custeio de novos projetos e investimentos, além do comportamento organizacional e otimização de processos produtivos."
      },
      mauricio: {
        name: "Mauricio",
        text_1:
          "Mauricio Piacentini tem mais de 25 anos de experiência como lead designer e programador, tendo participado do desenvolvimento de mais de 200 aplicativos e jogos. Como programador fundador e líder da empresa Tabuleiro ele coordenou o design e o desenvolvimento de pacotes de middleware para as ferramentas Macromedia Shockwave nos anos 90, utilizados por dezenas de milhares de desenvolvedores em mais de 90 países diferentes.",
        text_2:
          "Depois disso, Mauricio ajudou a fundar a 2Mundos Inc e lidera a equipe de engenharia e P & D. Um programador habilidoso com um forte histórico de C / C ++, ele desenvolveu para a maioria dos sistemas operacionais de desktop lançados desde 1994, incluindo todos os tipos de Windows, MacOS e Linux. Possui profundo conhecimento de uma variedade de linguagens de script e programação, frameworks e APIs, incluindo Carbon/Cocoa, Win32, DirectX, OpenGL, Qt, Linux, JavaScript, PHP, Java e outros."
      },
      raphael: {
        name: "Raphael",
        text_1:
          "Raphael Hamzagic já acumula duas décadas de experiência como programador tendo passado por empresas produtoras de projetos multimídia, estúdio de games e uma das principais agências de publicidade do mercado. O alto grau de interatividade e inovação tecnológica estão presentes nos principais projetos que marcaram sua trajetória.",
        text_2:
          "Raphael está na 2Mundos desde sua fundação, sempre buscando produzir com eficiência, qualidade e rigorosamente no prazo. Essas três características cativaram os clientes norte-americanos, com quem tem trabalhado de perto nos últimos 5 anos."
      },
      bernardo: {
        name: "Bernardo",
        text_1:
          "Bernardo Manfredini é formado em Publicidade e Propaganda pela ECA-USP, pós-graduado em Administração de Empresas pelo IBMEC e tem um Máster em Project Management pela EAE Business School de Madri. Começou a atuar em tecnologia há mais de 15 anos na Ubisoft, passando por empresas como UOL e iG antes de começar a empreender. Em 2008 formou parte da equipe fundadora do clube de compras Coquelux, iniciativa que passou por rodadas de investimentos de fundos como Intel Capital. ",
        text_2:
          "Desde 2011 Bernardo faz parte da 2Mundos, onde chegou logo após sua fundação. Tem atuado em diversas áreas da empresa sempre com o foco na busca de novos negócios, que os projetos superem as expectativas dos clientes e que a empresa possa ter um ambiente de trabalho especial para reter talentos, sem nunca perder de vista a eficiência da organização."
      },
      antonio: {
        name: "Antonio",
        text_1:
          "Antonio Marcio Curi trabalha na indústria de tecnologia há mais de 15 anos. Iniciou sua experiência profissional em San Diego, na Hands-on Mobile, como desenvolvedor líder de aplicativos e jogos para dispositivos móveis, onde ocupou diversos cargos nas equipes de engenharia e negócios. Depois disso, Antonio foi contratado e transferido para o Brasil pela Electronic Arts, a maior empresa de videogames do mundo, para auxiliar na abertura de operações da empresa na América Latina.",
        text_2:
          "Após 4 anos na EA, Antonio mudou-se para a Qualcomm Inc, onde deixou o cargo de Gerente Sênior de Desenvolvimento de Negócios na América Latina, para ser um dos cofundadores da 2Mundos Inc em 2011. Desde então, a 2Mundos cresceu contando hoje com mais de 70 funcionários atendendo clientes em todo o mundo. Antonio é formado em Engenharia de Computação e assessor em diversas empresas de tecnologia ao redor do mundo."
      }
    },
    our_teams: {
      title: "Nossos Times",
      ux_ui: {
        title: "UX/UI",
        text_1:
          "A experiência do usuário é o que importa! Experiência do Usuário (UX) e Interface do Usuário (UI) são peças importantes para o sucesso da estratégia no mundo digital. Não há porque escolher apenas um deles, ou achar que competem entre si. UX é responsável pela sensação do usuário ao acessar uma aplicação ou navegar por um conteúdo, enquanto UI é responsável por guiar o usuário durante o uso da solução.",
        text_2: "UI é sobre razão, UX é sobre emoção.",
        text_3:
          "Com a evolução da tecnologia e a quantidade de serviços e produtos oferecidos atualmente, as empresas que se destacam são justamente as que pensam na experiência do cliente e nisso a 2Mundos é especialista. Todas as interfaces de produtos são elaboradas por nossa equipe de UX/UI, juntamente com designers e ilustradores, tornando a experiência e navegação mais rápida, prática e intuitiva."
      },
      iot: {
        title: "IOT",
        text:
          "Precisa de uma solução em hardware? Nosso time de IoT está pronto para pensar e desenvolver qualquer projeto, seja ele um hardware, o desenvolvimento de uma placa de circuito impresso (PCB), circuitos analógicos ou digitais e firmware para plataformas 8 ou 32 bits. "
      },
      ai: {
        title: "INTELIGÊNCIA ARTIFICIAL",
        text:
          "A inteligência Artificial está mudando a maneira de se fazer negócios em diversos segmentos do mercado. Mais e mais empresas estão utilizando a inteligência artificial para economizar recursos e aumentar sua produtividade, sejam elas gigantes do mercado ou startups. A inteligência artificial aplicada ao mundo dos negócios permite análises de enormes volumes de dados em pouco tempo. Processos que demoravam dias ou semanas, agora podem ser realizados em poucos minutos.  Nossas plataformas de inteligência artificial são facilmente escaláveis e se integram perfeitamente com a infra-estrutura tecnológica existente. Precisando de mais inteligência para o seu negócio? Vem para a 2Mundos!"
      },
      engenharia: {
        title: "ENGENHARIA DE SOFTWARE",
        text:
          "Seu projeto pensado para ser leve, ágil, usando as melhores tecnologias do mercado e com o que há de mais moderno em hardware e software. Nossas equipes estão sempre em busca de novidades para que você tenha o melhor produto. A fundação é a base de tudo, por isso na 2Mundos primeiro entendemos as necessidades do cliente e requisitos do produto a ser desenvolvido. Após a análise dessas informações, nossos engenheiros de software avaliam toda a estrutura, pensando nas melhores tecnologias, linguagens e plataformas para que a solução atenda integralmente às expectativas do usuário final."
      },
      infra: {
        title: "INFRAESTRUTURA",
        text:
          "Uma infraestrutura tecnológica bem definida é a base para qualquer projeto. A decisão sobre uma infraestrutura tem importância estratégica, já que limitará ou potencializará a escalabilidade da solução. Por esse motivo, pensamos em todos os seus componentes de software e hardware. Uma infraestrutura sólida permitirá que a solução opere de maneira eficiente e eficaz, com elevados níveis de serviço e benefícios."
      },
      ar_vr: {
        title: "AR/VR",
        text:
          "Presentes cada vez mais em nosso dia a dia, as tecnologias de realidade virtual e realidade aumentada estão sendo utilizadas em jogos, aplicativos, além de áreas como a medicina e a educação. Elas permitem trazer elementos do mundo virtual para o mundo real, mudando assim nossa percepção de situações complexas, oferecendo suporte a diversas tarefas do cotidiano. Com a expertise da 2Mundos, é possível desenvolver ambientes totalmente digitais, que permitem ao usuário ter uma imersão no assunto, como é o caso da realidade virtual, ou simplesmente inserindo informações virtuais no mundo real ,no caso da Realidade Aumentada. Dê vida a sua idéia! Seja ela através da realidade virtual ou aumentada, a 2Mundos possui vasta experiência na criação de experiências virtuais para treinamentos, educação, entretenimento ou ações de marca, utilizando as mais novas ferramentas de mercado."
      },
      gestao: {
        title: "GESTÃO DE PROJETOS",
        text:
          "Em meio ao cenário atual de constantes mudanças tecnológicas, a gestão de projetos vem se tornando uma peça fundamental na melhoria da performance de uma empresa. Com o passar dos anos, as estruturas empresariais tornaram-se mais complexas, exigindo que todos se adaptem às novas realidades do mercado. A equipe de gestão de projetos da 2Mundos acompanha cada projeto desde o planejamento, desenvolvendo cronogramas de entregas e gerenciamento de riscos, passando por todo monitoramento durante a execução e conclusão de cada etapa até a entrega do produto final, utilizando as mais recentes metodologias para acompanhamento e controle. Um projeto bem administrado se torna mais ágil e assertivo, reduzindo custos, tempo de desenvolvimento e riscos."
      },
      qa: {
        title: "QUALITY ASSURANCE",
        text:
          "Atestamos a qualidade de nossos produtos em cada fase do projeto, através de uma equipe altamente treinada, reduzindo a probabilidade de falhas. Nossa equipe de QA é responsável por testar e atestar a qualidade de nossas soluções realizando testes exaustivos a cada nova versão de build. Dessa forma, garantimos maior estabilidade nas entregas e redução de riscos de falhas no produto final, pois os problemas são sanados com mais rapidez."
      }
    }
  },
  jobs: {
    title: "Trabalhe Conosco",
    apply: "Candidate-se",
    oportunities: {
      title: "Oportunidades",
      text_1:
        "Venha fazer parte de uma empresa que tem como propósito desenvolver e incentivar o melhor das pessoas!",
      text_2:
        "Tendo a inovação em nosso DNA, nos empenhamos em criar os melhores produtos para os nossos clientes. Temos orgulho de trabalhar com empresas na América Latina, EUA e Europa. Nossos clientes são exigentes, por isso formamos uma equipe de alto nível, composta por profissionais realmente interessados em trabalhar seguindo os exigentes padrões internacionais de prazo e qualidade. Na 2Mundos você vai encontrar um ambiente que desperta a criatividade, incentiva a autonomia, te encoraja a buscar novas habilidades e se desafiar todos os dias.",
      text_3:
        "Somos uma empresa que acredita nas pessoas e promove a diversidade. Por isso, incentivamos um ambiente profissional que valorize as diferenças e seja guiado sempre pela meritocracia, não pelas aparências, crenças ou visões de mundo. Profissionais que evoluem rapidamente dentro de nosso ambiente, rapidamente são convidados a assumir maiores responsabilidades e veem a carreira acelerar em pouco tempo. Temos diversos exemplos assim em nossos times. Buscamos profissionais engajados e com grande senso de realização. Se você tem paixão pelo que faz, vontade de aprender, e espírito colaborativo, junte-se a nós!",
      text_4: "Confira abaixo algumas vagas abertas."
    }
  },
  contact: {
    title: "Contato",
    text:
      "2Mundos tiene presencia en Estados Unidos, Latino América y Europa. Contamos con oficinas físicas en San Diego, São Paulo y Madrid. ¿Necesitas poner una idea en práctica? ¿Quieres convertirla en un producto o servicio? Estamos aquí para concretar tu idea en un producto.",
    form: {
      placeholder: {
        name: "Nombre",
        email: "Email",
        phone: "Telefono",
        message: "Mensaje",
        dont_fill: "If you are seeing this, do not fill out this input.",
        error_message:
          "Ups ... ocurrió algún problema, por favor inténtalo de nuevo!"
      }
    }
  },
  header: {
    home: "Home",
    about: "Quienes somos",
    cases: "Cases",
    jobs: "Trabaja con nosotros"
  },
  footer: {
    links: {
      home: "Home",
      about: "Quienes somos",
      cases: "Cases",
      jobs: "Trabaja con nosotros"
    },
    all_rights_reserved: "todos los derechos reservados"
  }
};

export default globals_es;
